.site-header {
  position: relative;
  padding: 1em 0 1em;
}

.site-title {
  color: $white-color;
  background-color: #00848a;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  letter-spacing: 2px;
  font-size: 1.2rem;
}

/* Nav */

.site-nav {
  float: right;
  margin-bottom: 1em;
  padding-top: 0.2rem;


  .menu-icon {
    display: none;
  }

  .page-link {
    display: inline;
    padding: 1px 0;
    margin-left: 1em;

    &:hover {
      color: $white-color;
    }
  }
}

@media (max-width: 350px) {

    .site-nav {
        float: left;
		margin: 0 0 0 -14px;
        }
    }
