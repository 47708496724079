html,
body,
div,
span,
iframe,
object,
h1,
h2,
h3,
h4,
h5,
h6,
p,
form,
label {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

html,
body { height: 100%; }

/* Box sizing overrides */

header,
div,
section,
aside,
main,
footer {
  box-sizing: border-box;
}