/* Page content */

.page-content {
  padding: 0;
}

.page-heading {
  margin: 0 0 1em;
  text-transform: capitalize;
}
