@import url(//fonts.googleapis.com/css?family=Inconsolata:400,700|Montserrat:700);

// Typography
$font-family-base: 'Inconsolata', sans-serif;
$font-family-sub:  'Inconsolata', sans-serif;

$font-size-base:   18px;
$line-height-base: 1.5;

$font-size-mobile: 16px;

$font-weight-base-normal: 600;
$font-weight-base-bold:   700;

$font-weight-sub-normal:  400;
$font-weight-sub-bold:    700;

$font-size-small: 0.85rem;

$spacing-unit: 1em;

// Color Scheme
$text-color:            #383838;
$text-color-light:      #bbb;
$title-color:           #000;

$background-color:      #fff;

$brand-color-base:      #00848a;
$brand-color-sub:       #000;

$white-color:           #fff;

$grey-color:   lighten($text-color-light, 20%);
$border-color: lighten($text-color-light, 20%);

// Max width of the content area
$content-width: 800px;

// Breakpoints
$reflow-point:  860px;
