body {
  font-family: $font-family-base;
  font-size: 1rem;
  line-height: 1.66;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
nav,
.site-header {
  font-family: $font-family-base;
  font-weight: $font-weight-base-bold;
}

.site-header {
  line-height: 2;
}

h1, h2, h3, h4, h5 {
  line-height: 1.1;
}

h1 { font-size: 2rem; }
h2 { font-size: 1.44rem; }
h3 { font-size: 1.44rem; }

h4 {
  font-size: 1.25rem;
  font-weight: $font-weight-base-normal;
}

h5 { font-size: 1.25rem; }
h6 { font-size: 1rem; }

a {
  font-weight: $font-weight-base-bold;
}

ol,
ul,
p {
  font-family: $font-family-sub;
  font-size: 1rem;
  line-height: 1.66;
}

blockquote p {
  font-family: $font-family-base;
  font-weight: $font-weight-base-normal;
}

hr {
  width: 100%;
  height: 1px;
  border: none;
  margin: 1em 0;
  background: $border-color;
  display: block;
}
